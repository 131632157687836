/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'Accordion',
        import: () => import('@stories/Widgets/Accordion/Accordion'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AreaMap',
        import: () => import('@stories/Widgets/AreaMap/AreaMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CardGrid',
        import: () => import('@stories/Widgets/CardGrid/CardGrid'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContactUsForm',
        import: () => import('@stories/Widgets/ContactUsForm/ContactUsForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContentInnerBanner',
        import: () => import('@stories/Widgets/Banners/ContentInnerBanner/ContentInnerBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CookieDeclaration',
        import: () => import('@stories/Widgets/CookieDeclaration/CookieDeclaration'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Features',
        import: () => import('@stories/Widgets/Features/Features'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FloorPlan',
        import: () => import('@stories/Widgets/FloorPlan/FloorPlan'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Footer/Footer'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Header',
        import: () => import('@stories/Widgets/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'HeroBanner',
        import: () => import('@stories/Widgets/Banners/HomeHeroBanner/HomeHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageCarousel',
        import: () => import('@stories/Widgets/ImageCarousel/ImageCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InformationCard',
        import: () => import('@stories/Widgets/InformationCard/InformationCard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InformationCarousel',
        import: () => import('@stories/Widgets/InformationCarousel/InformationCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageGallery',
        import: () => import('@stories/Widgets/ImageGallery/ImageGallery'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LevelGuide',
        import: () => import('@stories/Widgets/LevelGuide/LevelGuide'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LogoStrip',
        import: () => import('@stories/Widgets/LogoStrip/LogoStrip'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ShareButtons',
        import: () => import('@stories/Widgets/ShareButtons/ShareButtons'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SiteMap',
        import: () => import('@stories/Widgets/SiteMap/SiteMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SkipToContent',
        import: () => import('@stories/Widgets/SkipToContent/SkipToContent'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'StickyNavigation',
        import: () => import('@stories/Widgets/StickyNavigation/StickyNavigation'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'TabbedComponent',
        import: () => import('@stories/Widgets/TabbedComponent/TabbedComponent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TestimonialQuote',
        import: () => import('@stories/Widgets/TestimonialQuote/TestimonialQuote'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TransportLinks',
        import: () => import('@stories/Widgets/TransportLinks/TransportLinks'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VirtualTour',
        import: () => import('@stories/Widgets/VirtualTour/VirtualTour'),
        config: {
            hydrate: 'in-view',
        },
    }
];
